import PubSub from "pubsub-js";
import $ from "jquery";

class PageHeaderMenu {
    constructor() {
        PubSub.subscribe("ready", this.init.bind(this));
    }
    init() {
        function offset(el) {
            var rect = el.getBoundingClientRect(),
                scrollLeft =
                    window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop =
                    window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
        }

        var pageMenu = document.querySelector("#page-menu");
        var pageMenuOffset = null;

        function isAtTopOfPage() {
            pageMenu.classList.toggle(
                "page-menu--sticky",
                window.scrollY + (window.innerWidth < 1200 ? 100 : 50) > pageMenuOffset.top
            );
            window.requestAnimationFrame(isAtTopOfPage);
        }
        if (pageMenu) {
            pageMenuOffset = offset(pageMenu);
            window.requestAnimationFrame(isAtTopOfPage);
        }
    }
}

export default new PageHeaderMenu();
