const PubSub = window.PubSub;
const $ = window.jQuery;

class ArchiveFilter {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
	}
	init() {
		$('form[role="filter"]').each(function(index, elem) {
			var filterForm = $(elem);
			var filterInput = filterForm.find('input[type="search"]');
			var itemSelector = filterForm.attr('data-item-selector');
			var itemDataSelector = filterForm.attr('data-item-data-selector');
			var alert = filterForm.find('.alert');

			var filter = function(text) {
				var count = 0;
				var query = String(text).toLowerCase().trim();

				$(itemSelector).each(function(index, elem) {
					var item = $(elem);
					var dataElem = item.find(itemDataSelector);
					var data = String(dataElem.text());

					if (query.length === 0 || data.toLowerCase().indexOf(query) != -1) {
						item.css('display', 'block');
						count++;
					} else {
						item.css('display', 'none');
					}
				});

				if (count === 0) {
					alert.show();
				} else {
					alert.hide();
				}
			};

			filterInput.on('input', function(event) {
				filter($(this).val());
			});

			filterForm.on('submit', function(event) {
				event.preventDefault();
				filter(filterInput.val());
			});
		});

		$('form[role="category-filter"]').each(function(index, elem) {
			var filterForm = $(elem);
			var itemSelector = filterForm.attr('data-item-selector');
			var filterButtons = filterForm.find('button[data-filter]');
			var noFilterButton = filterForm.find('button[data-filter="all"]');

			var filter = function() {
				var selectedBtns = filterButtons.filter('.selected');
				if (selectedBtns.length === 0) {
					noFilterButton.addClass('selected');
					selectedBtns = noFilterButton;
				}

				var filters = selectedBtns.map(function (i, b) {
					return $(b).attr('data-filter');
				}).toArray();

				console.log(filters);
				if (filters.indexOf('all') != -1) {
					$(itemSelector).show();
				} else {
					$(itemSelector).each(function(index, elem) {
						var itemCats = $(elem).attr('data-categories');
						itemCats = itemCats ? itemCats.split(',') : [];

						var hasCat = false;
						for (var i in itemCats) {
							var c = itemCats[i];
							if (filters.indexOf(c) != -1) {
								hasCat = true;
								break;
							}
						}

						if (hasCat) {
							$(elem).show();
						} else {
							$(elem).hide();
						}
					});
				}
			};

			filterButtons.on('click', function(event) {
				var btn = $(this);
				var value = btn.attr('data-filter');

				if (value === 'all') {
					filterButtons.removeClass('selected');
					btn.addClass('selected');
				} else {
					filterButtons.removeClass('selected');
					noFilterButton.removeClass('selected');
					if (btn.hasClass('selected')) {
						btn.removeClass('selected');
					} else {
						btn.addClass('selected');
					}
				}
			});

			filterForm.on('submit', function(event) {
				event.preventDefault();
				filter();
			});

			filter();
		});
	}
}

export default new ArchiveFilter();

