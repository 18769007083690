const PubSub = window.PubSub;
const $ = window.jQuery;

class ImageFrame {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
	}
	init() {
		this.imageFrames = $('.image-frame.zoom').wrap($('<div class="image-frame-wrapper"></div>'));
	}
}

export default new ImageFrame();