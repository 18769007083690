const PubSub = window.PubSub;
const $ = window.jQuery;

class UserAgent {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
	}
	init() {
		var b = document.documentElement;
		b.className = b.className.replace('no-js', 'js');
		b.setAttribute('data-useragent',  navigator.userAgent);
		b.setAttribute('data-platform', navigator.platform );
	}
}

export default new UserAgent();




