const PubSub = window.PubSub;
const $ = window.jQuery;

class SearchWidget {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
	}
	init() {
		$('body.blog, body.search-type-post, body.single-post, body.archive.category')
			.find('aside.sidebar .widget_search form, .alert.alert-warning form')
			.append($('<input type="hidden" name="post_type" value="post">'));
	}
}

export default new SearchWidget();