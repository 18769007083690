const PubSub = window.PubSub;
const $ = window.jQuery;

class NavDropdown {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));

		this.mainMouseHandler = this.mainMouseHandler.bind(this);
	}

	mainMouseHandler(event) {
		var main = $('main');

		if ($('body').hasClass('show-dropdown-nav')) {
			waitReadySetGo(333, (d) => {
				d.offHandler = () => {
					d.cancel();
				};
				main.on('mouseleave', d.offHandler);
			}, (d) => {
				this.closeDropdownNav();
			}, (d) => {
				main.off('mouseleave', d.offHandler);
			});
		}
	}

	isDropdownNavOpened() {
		return $('body').hasClass('show-dropdown-nav');
	}

	openDropdownNav() {
		$('nav.dropdown').css('visibility', 'visible');

		$('body')
			.addClass('lock-scroll')
			.addClass('curtain-content')
			.addClass('show-dropdown-nav')
	}

	closeDropdownNav() {
		var body = $(document.body);

		if (body.hasClass('show-dropdown-nav')) {
			body
				.removeClass('lock-scroll')
				.removeClass('curtain-content')
				.removeClass('show-dropdown-nav')

			$('nav.dropdown')
				.find('.right .panel')
				.removeClass('active')
				.removeClass('inactive');

			$('header .dropdowns .menu-item')
				.removeClass('active');

			setTimeout(function() {
				$('nav.dropdown').css('visibility', '');
			}, 333);
		}

		$('main').off('mouseenter', this.mainMouseHandler);
	}

	selectDropdownItem(item) {
		var self = this;
		var target = item.attr('data-target');

		PubSub.publish('closeSearchBar');

		this.openDropdownNav();

		$('header .dropdowns .menu-item')
			.removeClass('active')
			.has(`a[data-target="${ target }"]`)
			.addClass('active');

		$('nav.dropdown .dropdown-item')
			.addClass('inactive')
			.removeClass('active')
			.filter(`[data-dropdown="${ target }"]`)
			.addClass('active')
			.removeClass('inactive')
			.find('.left li, .right .panel')
			.removeClass('active')
			.removeClass('inactive')


		$('main').on('mouseenter', this.mainMouseHandler);
	}

	selectDropdownItemPanel(item) {
		var self = this;
		var target = item.attr('data-target');

		$('nav.dropdown .dropdown-item .left li')
			.addClass('inactive')
			.removeClass('active')
			.filter(`[data-target="${ target }"]`)
			.addClass('active')
			.removeClass('inactive');	

		$('nav.dropdown .dropdown-item .right .panel')
			.addClass('inactive')
			.removeClass('active')
			.filter(`[data-panel="${ target }"]`)
			.addClass('active')
			.removeClass('inactive');	
	}

	init() {


		if ($('.nav-ms').length) {
			// 1st layer hover
			$('.nav-ms > li.menu-item-has-children > .sub-menu > li.menu-item-has-children').on('mouseover', function(event) {
				var menu = $(this).parent('ul.sub-menu');
				var menuHeight = menu.outerHeight();
				var subMenu = $(this).children('ul.sub-menu');
				var subMenuHeight = subMenu.outerHeight();

				if (subMenuHeight > menuHeight) {
					menu.css('height', subMenuHeight);
				}
				if (menuHeight > subMenuHeight) {
					subMenu.css('height', menuHeight);
				}				
			});
			$('.nav-ms > li.menu-item-has-children > li.menu-item-has-children').on('mouseout', function(event) {
				var menu = $(this).parent('ul.sub-menu');
				var subMenu = $(this).children('ul.sub-menu');
				menu.css('height', 'auto');
				subMenu.css('height', 'auto');
			});
			// 2nd layer hover
			$('.nav-ms > li.menu-item-has-children > .sub-menu > li.menu-item-has-children > .sub-menu > li.menu-item-has-children').on('mouseover', function(event) {
				var menu = $(this).parent('ul.sub-menu'); // get 2nd level
				var menuHeight = menu.outerHeight();
				var subMenu = $(this).children('ul.sub-menu');
				var subMenuHeight = subMenu.outerHeight();

				// console.log('hovering 2', {menu, menuHeight, subMenu, subMenuHeight});

				if (subMenuHeight > menuHeight) {
					menu.css('height', subMenuHeight);
				}
				if (menuHeight > subMenuHeight) {
					subMenu.css('height', menuHeight);
				}				
			});
			$('.nav-ms > li.menu-item-has-children > a').on('click', function(event) {
				event.preventDefault();
				var li = $(this).parent();
				var docWidth = $(document).width();
				var rightSpace = docWidth - li.offset().left - li.width(); 

				// if (rightSpace < 300) {
					li.find('> .sub-menu').addClass('flipped');
				// }

				if (li.hasClass('show-children')) {
					li.removeClass('show-children');
				} else {
					$('.nav-ms > li.menu-item-has-children.show-children').removeClass('show-children');
					li.addClass('show-children');
				}
			});
			$(document).on('click', function(event) {
				if ($('.nav-ms .show-children').length) {
					if (!$.contains($('.nav-ms .show-children')[0], event.target)) {
						$('.nav-ms > li.menu-item-has-children.show-children').removeClass('show-children');
					}
				}
			});
			return;
		}
		
		var self = this;
		var delay = 333;

		var offInterval = null;

		var header = $('header').on('mouseover', function(event) {
			var link = null;
			var listItem = null;
			var elem = $(event.target);
			var isNavItem = elem.hasClass('menu-item') || elem.parent().hasClass('menu-item');

			if (isNavItem) {
				link = elem.hasClass('menu-item') ? elem.children('a[data-target]') : elem;
				listItem = link.parent();
				isNavItem = listItem.hasClass('menu-item-has-children');
			}

			if (isNavItem) {
				if (offInterval) {
					clearInterval(offInterval);
					offInterval = null;
				}

				if (link.parent().hasClass('menu-item-has-children')) {
					waitReadySetGo(333, (d) => {
						d.offHandler = () => {
							d.cancel();
						};
						link.on('mouseleave', d.offHandler);
					}, (d) => {
						self.selectDropdownItem(link);
					}, (d) => {
						link.off('mouseleave', d.offHandler);
					});
				}
			} else {
				if (offInterval == null) {
					offInterval = setInterval(function() {
						self.closeDropdownNav();
						clearInterval(offInterval);
						offInterval = null;
					}, 333);
				}
			}
		});

		$('nav.dropdown button.close-dropdown').on('click', function() {
			self.closeDropdownNav();
		});

		$('nav.dropdown').on('mouseenter', function(event) {
			if (offInterval) {
				clearInterval(offInterval);
				offInterval = null;
			}
		});

		$('nav.dropdown .dropdown-item .left li').each(function(index, elem) {
			var listItem = $(elem).on('mouseenter', function(event) {
				waitReadySetGo(333, (d) => {
					d.offHandler = () => {
						d.cancel();
					};
					listItem.on('mouseleave', d.offHandler);
				}, (d) => {
					self.selectDropdownItemPanel(listItem);
				}, (d) => {
					listItem.off('mouseleave', d.offHandler);
				});
			});
		});
	}
}

export default new NavDropdown();