const PubSub = window.PubSub;
const $ = window.jQuery;

class NavSide {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
	}

	showingSideNav() {
		return $('body').hasClass('show-side-nav');
	}

	showSideNav() {
		$('.overlay-nav .curtain')
			.removeClass('visible')
			.removeClass('hidden')
			.css('display', 'block')
			.addClass('visible');

		$('body').addClass('show-side-nav');
	}

	hideSideNav() {
		$('.overlay-nav .curtain')
			.removeClass('visible')
			.removeClass('hidden')
			.css('display', 'block')
			.addClass('hidden');

		$('body').removeClass('show-side-nav');
	}

	toggleSideNav() {
		if (this.showingSideNav()) {
			this.hideSideNav();
		} else {
			this.showSideNav();
		}
	}

	init() {
		// Prepend a Home link
		$('nav.side > ul').prepend($('<li class="menu-item menu-home"><a href="/">Home</a></li>'));

		// Wrap link content in span
		$('nav.side a').each((index, elem) => { $(elem).html('<span>' + $(elem).html() + '</span>'); });

		// Hamburger button click handler
		$('#hamburger').on('click', () => { this.toggleSideNav(); });

		// Close button click handler
		$('#close-side-nav').on('click', () => { this.hideSideNav(); });

		// Curtain click handler
		$('.overlay-nav .curtain').on('click', () => { this.hideSideNav(); });

		// Add toggle button to expandable items
		$('nav.side .menu-item-has-children > a').each(function(index, elem) {
			var btn = $('<button class="toggle"></button>');
			btn.on('click', function(event) {
				event.preventDefault();
				var button = $(this);
				var item = button.parent().parent();
				if (item.hasClass('expanded')) {
					item.children('.sub-menu').slideUp();
				} else {
					item.children('.sub-menu').slideDown();
				}
				item.toggleClass('expanded');
			});
			$(elem).prepend(btn);
		});
	}
}

export default new NavSide();