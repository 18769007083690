const PubSub = window.PubSub;
const $ = window.jQuery;

class ResizeIframe {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
	}
	init() {

		// TODO:
		
		// <script>
		// var contactFormIframe = document.getElementById('content-form-iframe');
		// window.addEventListener('message', function(event) {
		//    if (event && event.data) {
		//       var data = (typeof event.data == 'string') ? JSON.parse(event.data) : data;
		//       if (data && data.scrollHeight) {
		//          contactFormIframe.setAttribute('height', data.scrollHeight + 'px');
		//       }
		//    }
		// });
		// </script>		
	}
}

export default new ResizeIframe();