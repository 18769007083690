// import external dependencies
// import jQuery from 'jquery';
// import select2 from 'select2';

// window.$ = window.jQuery = jQuery;
// $.fn.select2 = select2;

const $ = window.jQuery;
const PubSub = window.PubSub;

import 'slick-carousel';

// Import everything from autoload
import './autoload';

// Import everything from components
import './components';

// Setup PubSub for components
$(function () {
    PubSub.publish('ready');

    window.$ = jQuery;

    var draw = function () {
        var w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            x = w.innerWidth || e.clientWidth || g.clientWidth,
            y = w.innerHeight || e.clientHeight || g.clientHeight;

        PubSub.publish('draw', { width: x, height: y });

        window.requestAnimationFrame(draw);
    };
    window.requestAnimationFrame(draw);
});

// JS for all pages
$(function () {
    // JavaScript to be fired on all pages, after page specific JS is fired

    var header = document.querySelector('header#banner');
    var miniHeaderThreshold = 100; // the header 100px tall and 50 px from the top of the page. so 150px is halfway down the header

    var blogHeader = document.querySelector('main .header--feature-highlights');
    var blogHeaderHighlistsThreshold = 2;

    function isAtTopOfPage() {
        header.classList.toggle('show-mini-header', window.scrollY > miniHeaderThreshold);

        if (blogHeader) {
            blogHeader.classList.toggle('header--show-highlights', window.scrollY > blogHeaderHighlistsThreshold);
        }
        window.requestAnimationFrame(isAtTopOfPage);
    }
    window.requestAnimationFrame(isAtTopOfPage);

    $('body.post-type-archive-download .widget_search form, body.single-download .widget_search form').each(function (index, element) {
        var form = $(this);
        var hiddenInput = $('<input type="hidden" name="post_type" value="download" />');
        form.append(hiddenInput);
    });

    $('body.post-type-archive-video .widget_search form, body.single-video .widget_search form').each(function (index, element) {
        var form = $(this);
        var hiddenInput = $('<input type="hidden" name="post_type" value="video" />');
        form.append(hiddenInput);
    });

    $('.page-header.full_width.has-slides').slick({
        adaptiveHeight: false,
        arrows: false,
        dots: true,
        dotsClass: 'slick-dots',
        autoplay: true,
        speed: 500,
        pauseOnHover: false,
        autoplaySpeed: 6000,
    });

    // Add hover description to Uber menu items
    $('header nav.ubermenu-main > ul > li').each(function (index, element) {
        var $topMenuItem = $(element);
        var $ubermenuSubmenu = $topMenuItem.children('ul.ubermenu-submenu');

        var $firstTwoColumnWrapper = $('<div class="first-two-column-wrapper"></div>');
        var $menuDescHoverContainer = $('<div class="menu-desc-hover-container"></div>');

        if ($topMenuItem.hasClass('ubermenu-has-submenu-flyout')) {
            $firstTwoColumnWrapper.append($('<br style="clear: both;" />'));
            $firstTwoColumnWrapper.append($menuDescHoverContainer);
            $ubermenuSubmenu.append($firstTwoColumnWrapper);
        } else {
            var $ubermenuSubmenuItems = $ubermenuSubmenu.children('li.ubermenu-column');
            var wrappedColumnCount = $ubermenuSubmenuItems.length >= 2 ? 2 : $ubermenuSubmenuItems.length;

            if ($ubermenuSubmenu.children('li.ubermenu-column.ubermenu-column-auto').length == 0) {
                $firstTwoColumnWrapper.css('float', 'left').css('width', (100 * wrappedColumnCount) / $ubermenuSubmenuItems.length + '%');
                $ubermenuSubmenuItems.eq(0).css('width', 100 / wrappedColumnCount + '%');
                $ubermenuSubmenuItems.eq(1).css('width', 100 / wrappedColumnCount + '%');
            } else {
                $firstTwoColumnWrapper.css('float', 'left');
            }

            $firstTwoColumnWrapper.append($ubermenuSubmenuItems.eq(0), $ubermenuSubmenuItems.eq(1));
            $firstTwoColumnWrapper.append($('<br style="clear: both;" />'));
            $ubermenuSubmenu.prepend($firstTwoColumnWrapper);

            setTimeout(function () {
                var wrapperWidth = $firstTwoColumnWrapper.width();
                if (wrapperWidth > 100) {
                    $menuDescHoverContainer.css('width', wrapperWidth - 10 + 'px');
                }
                $firstTwoColumnWrapper.append($menuDescHoverContainer);
            }, 10);
        }

        $topMenuItem.find('.ubermenu-target-description').each(function (_, element) {
            var $menuDesc = $(element);
            var $menuAnchor = $menuDesc.parent();
            var $menuItem = $menuDesc.parents('.ubermenu-item');
            $menuItem = $menuItem.length ? $($menuItem.get(0)) : null;

            if ($menuItem) {
                $menuDesc.hide();

                var itemId = /ubermenu-item-(\d+)/gm.exec($menuItem.attr('class'))[1];
                $menuDesc.attr('item-target-id', itemId);

                var $targetDesc = $menuDesc.clone().hide().addClass('menu-desc');
                $menuDescHoverContainer.append($targetDesc);

                $menuAnchor.on('mouseenter', function () {
                    $menuDescHoverContainer.find('.menu-desc').hide();
                    $targetDesc.show();
                });
                $menuAnchor.on('mouseleave', function () {
                    $menuDescHoverContainer.find('.menu-desc').hide();
                });
            }
        });
    });
});
