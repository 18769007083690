const PubSub = window.PubSub;
const $ = window.jQuery;

class Search {
    constructor() {
        PubSub.subscribe('ready', this.init.bind(this));
    }
    init() {
        this.seeMores = $('.has-see-more').each(function (index, elem) {
            var list = $(elem);
            var seeMoreButton = list.find('.show-more');
            var seeMoreContainer = list.find('.see-more');

            seeMoreButton.on('click', function (event) {
                if (seeMoreButton.attr('data-expanded-display')) {
                    seeMoreContainer.slideDown({
                        start: function () {
                            $(this).css({
                                display: seeMoreButton.attr('data-expanded-display'),
                            });
                        },
                    });
                } else {
                    seeMoreContainer.slideDown();
                }
                seeMoreButton.hide();
                list.addClass('showing-more');
            });
        });
    }
}

export default new Search();
