const PubSub = window.PubSub;
const $ = window.jQuery;

class Search {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
		PubSub.subscribe('draw', this.draw.bind(this));

		PubSub.subscribe('openSearchBar', () => { this.focusSearchBar(); $('body').addClass('show-search-bar') });
		PubSub.subscribe('closeSearchBar', () => { this.blurSearchBar(); $('body').removeClass('show-search-bar') });

		this.mainMouseHandler = this.mainMouseHandler.bind(this);
	}

	mainMouseHandler(event) {
		var button = $('header .controls #search');
		if (!button.is(event.target) && !$.contains(button[0], event.target)) {
			this.closeSearchBar();
		}
	}

	blurSearchBar() {
		this.searchBarField.blur();
	}
	focusSearchBar() {
		this.searchBarField.focus();
	}
	isSearchBarOpened() {
		return $('body').hasClass('show-search-bar')
	}
	openSearchBar() {
		this.focusSearchBar();
		$('body')
			.addClass('show-search-bar')
			.addClass('lock-scroll')
			.addClass('curtain-content');

		$('main').on('click', this.mainMouseHandler);
		$('header').on('click', this.mainMouseHandler);
	}
	closeSearchBar() {
		this.blurSearchBar();
		$('body')
			.removeClass('show-search-bar')
			.removeClass('lock-scroll')
			.removeClass('curtain-content');

		$('main').off('click', this.mainMouseHandler);
		$('header').off('click', this.mainMouseHandler);
	}
	init() {
		var body = $('body');

		this.searchButton = $('header .controls #search');
		this.searchBar = $('.search-bar');
		this.searchBarField = this.searchBar.find('.search-field');

		this.headerSearchButton = $('header button#search').on('click', (e) => {
			if (this.isSearchBarOpened()) {
				this.closeSearchBar();
			} else {
				this.openSearchBar();
			}
		});

		var topSearchInput = $('header.top-bar .search-input');
		var clickOffHandler = (event) => {
			if (topSearchInput[0].contains(event.target) == false) {
				topSearchInput.removeClass('active');
				$(document).off('click', clickOffHandler);
			}
		}
		
		this.topSearchButton = $('header.top-bar button.activate-search').on('click', (e) => {
			if (topSearchInput.hasClass('active')) {
				var query = topSearchInput.find('input.search-field').val();
				if (query.length == 0) {
					topSearchInput.removeClass('active');
				} else {
					topSearchInput.find('form.search-form').trigger('submit');
				}
			} else {
				topSearchInput.find('input.search-field').trigger('focus');
				topSearchInput.addClass('active');
				$(document).on('click', clickOffHandler);
			}
		});
		topSearchInput.find('input.search-field').on('keyup', function (event) {
			if (String(event.key).toLowerCase() == 'escape') {
				$(this).val('').trigger('blur');
				topSearchInput.removeClass('active');
			}
		});
	}
	draw(topic, args) {

	}
}

export default new Search();