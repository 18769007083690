const $ = window.jQuery;
import 'select2';
const PubSub = window.PubSub;

class Select2 {
    constructor() {
        PubSub.subscribe('ready', this.init.bind(this));
    }
    init() {
        $('select:not(.select2-override)').css('width', '100%').select2({
            width: 'resolve',
            minimumResultsForSearch: -1,
        });
    }
}

export default new Select2();
