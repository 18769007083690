const PubSub = window.PubSub;
const $ = window.jQuery;

function time(sec) {
	var sec_num = sec;
	var hours   = Math.floor(sec_num / 3600);
	var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
	var seconds = sec_num - (hours * 3600) - (minutes * 60);

	if (hours   < 10) {hours   = "0"+hours;}
	if (minutes < 10) {minutes = "0"+minutes;}
	if (seconds < 10) {seconds = "0"+seconds;}
	return hours+':'+minutes+':'+seconds;
}

class YoutubeVideo {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
		PubSub.subscribe('draw', this.draw.bind(this));

		window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady.bind(this);
	}
	init() {
		// 2. This code loads the IFrame Player API code asynchronously.
		var tag = document.createElement('script');

		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		this.iframePlayers = [];
		this.youtubes = $('.component.youtube-video');
	}

	onYouTubeIframeAPIReady() {
		var self = this;

		this.youtubes.each(function(index, elem) {
			var youtube = $(elem);
			var duration = youtube.find('.duration');
			var placeholder = youtube.find('.placeholder');
			var iframeID = placeholder.attr('id');
			var videoID = placeholder.attr('data-video-id');

			var onPlayerReady = function(event) {
				if (event && event.target) {
					var d = event.target.getDuration();
					if (d) {
						duration.html(`${ time(d) }`);
					}
				}
			}

			var onPlayerStateChange = function(event) {
				console.log(event);
				switch(event.data) {
					case YT.PlayerState.ENDED:
					case YT.PlayerState.PLAYING:
					case YT.PlayerState.PAUSED:
					case YT.PlayerState.BUFFERING:
					case YT.PlayerState.CUED:
						youtube.addClass('isPlaying');
						break;
					default:
						youtube.removeClass('isPlaying');
						break;
				}

				if (event.data == YT.PlayerState.PLAYING) {
					for (var i in self.iframePlayers) {
						if (self.iframePlayers[i] != player) {
							self.iframePlayers[i].pauseVideo && self.iframePlayers[i].pauseVideo();
						}
					}
				}
			}

			var player = new YT.Player(iframeID, {
				height: '390',
				width: '640',
				videoId: videoID,
				playerVars: {
					rel: 0,
					showinfo: 0
				},
				events: {
					'onReady': onPlayerReady,
					'onStateChange': onPlayerStateChange
				}
			});

			self.iframePlayers.push(player);
		});
	}
	draw(topic, args) {

	}
}

export default new YoutubeVideo();
