function waitReadySetGo(wait, ready, set, go) {
	var scope = {};

	scope.id = setInterval(function() { 
		set(scope); 
		clearInterval(scope.id); 
		scope.id = null; 
		scope.final();
	}, wait);

	scope.cancel = function() { 
		clearInterval(scope.id); 
		scope.id = null; 
		scope.final() 
	};

	scope.final = function() { 
		go(scope); 
	}

	ready(scope); 

	return scope;
}

window.waitReadySetGo = waitReadySetGo;