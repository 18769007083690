const PubSub = window.PubSub;
const $ = window.jQuery;

class Comments {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
	}
	init() {
		$('form.comment-form[novalidate]').attr('novalidate', null);
	}
}

export default new Comments();

