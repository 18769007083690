const PubSub = window.PubSub;
const $ = window.jQuery;

class Carousel {
	constructor() {
		this.prevWidth = null;
		this.homeSlick = [];

		PubSub.subscribe('ready', this.init.bind(this));
		PubSub.subscribe('draw', this.draw.bind(this));
	}
	init() {
		this.homeSlick = jQuery('.home-slick');

		$('.home-slick').slick({
			adaptiveHeight: false,
			arrows: false,
			dots: true,
			dotsClass: 'slick-dots',
			vertical: true,
			verticalSwiping: true,
			autoplay: true,
			speed: 500,
			pauseOnHover: false,
			autoplaySpeed: 6000,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						vertical: false,
						verticalSwiping: false,
						adaptiveHeight: false,
					},
				},
			],
		});

		$('*[data-slick]').slick();

		/*
		{
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1, 
						slidesToScroll: 1
					}
				}
			]
		}
		*/

		$(window).on('resize orientationchange', function() {
			$('*[data-slick]').slick('resize');
		});

		if (this.homeSlick.length) {
			setInterval(() => {
				this.prevWidth = 0;
			}, 333);
		}
	}
	draw(topic, args) {
		if (args && args.width && this.homeSlick.length > 0) {
			if (this.prevWidth != args.width) {
				if (args.width < 768) {
					$('.home-slick').each(function(index, elem) {
						var slick = $(elem);
						var slides = slick.find('.slick-slide');
						var maxHeight = 0;

						slides.each(function(index, elem) {
							var slide = $(elem);
							slide.css('height', 'auto');

							var height = slide.outerHeight();
							if (height > maxHeight) {
								maxHeight = height;
							}
						});

						if (maxHeight > 0) {
							slides.css('height', maxHeight);
						}
					});
				} else {
					$('.home-slick').each(function(index, elem) {
						var slick = $(elem);
						var slides = slick.find('.slick-slide');
						slides.css('height', null);
					});
				}	
				
				this.prevWidth = args.width;			
			}
		}
	}
}

export default new Carousel();