const PubSub = window.PubSub;
const $ = window.jQuery;

class CategoryDetail {
	constructor() {
		PubSub.subscribe('ready', this.init.bind(this));
		PubSub.subscribe('draw', this.draw.bind(this));
	}
	init() {
		this.component = $('.component.category-detail');
		this.postList = $('.component.category-post-list');

		if (this.component.length && this.postList.length) {

			this.filterHeader = this.postList.find('.filter-header');
			this.filterGrid = this.postList.find('.filter-grid');

			this.tagCloud = this.component.find('.tag-filters .tag-cloud');
			this.tagSelect = this.component.find('.tag-filters .tag-select select');

			this.tagSelect.on('change', (event) => {
				this.selectTag(this.tagSelect.val(), this.tagSelect.find(':selected').text());
			});

			this.tagCloud.on('click', 'li', (event) => {
				this.selectTag($(event.target).attr('data-term-id'), $(event.target).text());
			});
		}
    }

    selectTag(tagId, tagName) {
	   	if (tagId && tagId != '-1' && tagName) {

	   		if (tagId == this.selectedTagId) {

				this.tagCloud
					.find('li')
					.removeClass('selected');

				this.tagSelect.val('-1').trigger('change.select2');

			   	this.filterHeader.find('span').text('');

			   	this.filterGrid
			   		.find('.filter-grid-item')
			   		.removeClass('filtered-in filtered-out')

	   			return;
	   		}

	   		this.selectedTagId = tagId;

			this.tagCloud
				.find('li')
				.removeClass('selected')
				.filter(`[data-term-id="${ tagId }"]`)
				.addClass('selected');

			this.tagSelect.val(tagId).trigger('change.select2');

		   	this.filterHeader.find('span').text(' : ' + tagName);
		   	this.filterGrid
		   		.find('.filter-grid-item')
		   		.addClass('filtered-out')
		   		.removeClass('filtered-in')
   				.filter((index, elem) => {
   					var tags = $(elem).attr('data-tags') || '';
   					tags = tags.split(',');
   					return tags.indexOf(String(tagId)) !== -1;
   				})
   				.addClass('filtered-in')
		   		.removeClass('filtered-out');

	   		$('html, body').animate({ 
	   			scrollTop: this.postList.offset().top - $('header.banner').height()
	   		});
	   	} else {

			this.tagCloud
				.find('li')
				.removeClass('selected');

			this.tagSelect.val('-1').trigger('change.select2');

		   	this.filterHeader.find('span').text('');

		   	this.filterGrid
		   		.find('.filter-grid-item')
		   		.removeClass('filtered-in filtered-out')
	   	}
    }

	draw(topic, args) {
        
	}
}

export default new CategoryDetail();