const $ = window.jQuery;

$.fn.max = function (selector) {
    return Math.max.apply(
        null,
        this.map(function (index, el) {
            return selector.apply(el);
        }).get()
    );
};

$.fn.min = function (selector) {
    return Math.min.apply(
        null,
        this.map(function (index, el) {
            return selector.apply(el);
        }).get()
    );
};

// Usage:
var maxWidth = $('a').max(function () {
    return $(this).width();
});
var minWidth = $('a').min(function () {
    return $(this).width();
});
